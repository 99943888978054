import { useCallback } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import useToken from '../useToken';
import {
  updateUserHistory,
  SESSION_KEYS,
  SESSION_TYPE,
} from './sessionHistoryService';

import {
  retrieveCurrentAreaName,
  retrieveCurrentOccName,
  retrieveResumeFileName,
  retrieveSelectedSkills,
  retrieveInput,
} from 'apps/skillsExplorer/services/inputService';

import { favouritesSignInAction } from 'components/ui/buttons/favouriteButton/Favourites';

export const SAVE_USER_PREFERENCES = gql`
  mutation SetUserPrefs($userid: String!, $preferences: JSON) {
    insertUserPref(input: { id: $userid, prefs: $preferences })
      @rest(path: "/users/preferences", method: "PUT", endpoint: "v1") {
      boolean
    }
  }
`;

export const GET_USER_PREFERENCES = gql`
  query GetUserPrefs($userid: String!) {
    userPreferences(userid: $userid)
      @rest(
        type: "Preferences"
        path: "/users/{args.userid}/preferences"
        method: "GET"
        endpoint: "v1"
      ) {
      id
      preferences
    }
  }
`;

export const PREF_KEYS = {
  INTERESTS_QUIZ: 'interests-quiz',
  SKILLS_EXPLORER: 'skills-explorer',
  FAVOURITES: 'favourites',
};

// Custom Hook for retrieving a specific users preferences from the database
export const useGetUserPreferences = currentUser => {
  const [getUserPreferences] = useLazyQuery(GET_USER_PREFERENCES, {});
  const { token } = useToken();

  const accessUserPreferences = useCallback(async () => {
    let results = {};
    if (currentUser.uid === token.uid) {
      await getUserPreferences({
        variables: {
          userid: currentUser.uid,
        },
        onCompleted: data => {
          if (data.userPreferences.preferences) {
            results = data.userPreferences.preferences;
          }
        },
      });
    }
    return results;
  }, [currentUser, getUserPreferences]);

  return {
    accessUserPreferences,
  };
};

// Custom Hook for saving a specific users preferences to the database
// returns 2 functions:
//  - saveUserPreferences: saves users preferences by uid, overrides
//  data for the specific prefs object argument.
//  - saveUserProgressOnLogin: saves users data based on the current url
//  basepath. This is needed to retrieve a users ongoing progress if they
//  are not logged in yet.
export const useSaveUserPreferences = () => {
  const { pathname } = useLocation();
  const { token } = useToken();

  const [getUserPreferences] = useLazyQuery(GET_USER_PREFERENCES);

  const [setUserPreferences] = useMutation(SAVE_USER_PREFERENCES, {
    refetchQueries: () => ['GetUserPrefs'],
  });

  const saveUserPreferences = async ({ uid, prefs }) => {
    if (uid === token.uid) {
      await getUserPreferences({
        variables: {
          userid: uid,
        },
        onCompleted: data => {
          if (data.userPreferences.preferences) {
            prefs = {
              ...data.userPreferences.preferences,
              ...prefs,
            };
          }
        },
      });
      await setUserPreferences({
        variables: {
          userid: uid,
          preferences: prefs,
        },
      });
    }
  };

  const saveUserProgressOnLogin = useCallback(
    async ({ uid }) => {
      // Tools where preferences are saved should be listed here and a
      // specific function for obtaining current data should be implemented.
      const getPreferencesStrategy = {
        [PREF_KEYS.INTERESTS_QUIZ]: getCurrentQuizPreferences,
        [PREF_KEYS.SKILLS_EXPLORER]: getCurrentSkillsPreferences,
      };

      function getCurrentQuizPreferences() {
        const params = new URLSearchParams(document.location.search);
        const input = params.get('answers');
        if (Number(input)) {
          updateUserHistory({
            storageType: SESSION_TYPE.PREFS,
            source: SESSION_KEYS.INTERESTS_QUIZ,
          });
          return {
            [PREF_KEYS.INTERESTS_QUIZ]: {
              answers: input,
            },
          };
        }
        return false;
      }

      function getCurrentSkillsPreferences() {
        const input = retrieveInput();
        if (Object.keys(input).length) {
          updateUserHistory({
            storageType: SESSION_TYPE.PREFS,
            source: SESSION_KEYS.SKILLS_EXPLORER,
          });
          return {
            [PREF_KEYS.SKILLS_EXPLORER]: {
              currentAreaName: retrieveCurrentAreaName(),
              currentOccName: retrieveCurrentOccName(),
              selectedSkills: retrieveSelectedSkills(),
              resumeFileName: retrieveResumeFileName(),
            },
          };
        }
        return false;
      }

      const basePath = pathname.split('/')[1];
      let progress = getPreferencesStrategy[basePath]?.();
      let prefs = false;
      let tempFav = false;
      await getUserPreferences({
        variables: {
          userid: uid,
        },
        onCompleted: data => {
          tempFav = sessionStorage.getItem('tempFav') || false;
          prefs = favouritesSignInAction({
            preferences: data.userPreferences?.preferences,
          });
        },
      });

      // If user has current progress or has just clicked the fav button
      // save to the db during login (prefs must exist, prevents erroneous mutations)
      if ((progress || tempFav) && prefs) {
        progress = {
          ...prefs,
          ...(progress || {}),
        };

        // If user has completed progress before logging
        // save the new progress to the database
        await setUserPreferences({
          variables: {
            userid: uid,
            preferences: progress,
          },
        });
      }
    },
    [pathname, getUserPreferences, setUserPreferences]
  );

  return {
    saveUserPreferences,
    saveUserProgressOnLogin,
  };
};
