import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import CookieConsent from 'react-cookie-consent';

const CookieBanner = () => {
  const theme = useTheme();

  const onResponse = () => {
    location.reload();
  };

  return (
    <CookieConsent
      enableDeclineButton
      flipButtons
      declineButtonText="Essential cookies only"
      buttonText="Allow all cookies"
      onAccept={onResponse}
      onDecline={onResponse}
      style={{
        backgroundColor: theme.palette.primary.main,
        justifyContent: useMediaQuery(`(min-width:2300px)`) ? 'start' : 'end',
      }}
      contentStyle={{
        color: theme.palette.white.main,
        flex: '0 0 1',
        margin: '0px 15px',
      }}
      buttonStyle={{
        backgroundColor: theme.palette.black.main,
        color: theme.palette.white.main,
        padding: '6px 16px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 600,
      }}
      declineButtonStyle={{
        backgroundColor: theme.palette.black.main,
        color: theme.palette.white.main,
        padding: '6px 16px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontWeight: 600,
      }}
    >
      <h3>
        <strong>Cookies on SkillUp</strong>
      </h3>
      <span>
        We use cookies to enable basic website functionalities and enhance your
        overall experience. With your consent, we would like to use analytical
        cookies from Google Analytics and Hotjar to track user interactions,
        gather feedback, and analyze our website traffic. Please read our{' '}
        <a
          href="/privacy"
          rel="external nofollow noopener noreferrer"
          target="_blank"
          style={{ color: theme.palette.black.main }}
        >
          Privacy Statement
        </a>{' '}
        for more information.
      </span>
    </CookieConsent>
  );
};

export default CookieBanner;
