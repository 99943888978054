import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import useStyles from '../style';
import {
  getCookieConsentValue,
  resetCookieConsentValue,
} from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import { ROUTE } from 'routes';
import { PAGES } from 'pages';

const PrivacyStatementText = () => {
  const classes = useStyles();
  ReactGA.send({
    hitType: 'pageview',
    page: ROUTE.PRIVACY,
    title: PAGES.PRIVACY,
  });

  const resetCookies = () => {
    resetCookieConsentValue();
    location.reload();
  };

  return (
    <>
      <Typography className={classes.note} variant="h7">
        Last updated: September 20, 2024
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Privacy Policy
      </Typography>
      <Typography className={classes.note} variant="h7">
        This Privacy Policy describes how Cybera Inc. (we) collect, use or
        otherwise process information about you as it relates to the SkillUp web
        application (the Site) as developed by Cybera Inc. a not-for-profit
        operating in Canada.
      </Typography>
      <Typography className={classes.note} variant="h7">
        This Privacy Policy only applies only if you visit{' '}
        <a
          className={classes.link}
          href="https://skill-up.ca"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          skill-up.ca
        </a>{' '}
        from within Canada.
      </Typography>
      <Typography className={classes.note} variant="h7">
        We may change this Privacy Policy from time to time. If we make changes,
        we will notify you by revising the date at the top of this policy. We
        encourage you to review this Privacy Policy regularly to stay informed
        about our information practices and the choices available to you.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Collection of Information
      </Typography>
      <Typography className={classes.note} variant="h7">
        The information we collect about you depends on how you interact with us
        or use the Site. In this section, we describe the categories of
        information we collect and the sources of this information.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Information You Provide to Us
      </Typography>
      <Typography className={classes.note} variant="h7">
        When you authenticate with the Site, you will be asked to provide your
        name and email address. Additionally, we may store information garnered
        or derived from your activity to improve your experience when using the
        Site; for example, to remember your choices between sessions.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Information We Collect Automatically
      </Typography>
      <Typography className={classes.note} variant="h7">
        We use essential cookies for basic functionalities as you navigate the
        Site. This includes saving your login details, tracking your navigation
        paths, and detecting your device's screen size for optimal display.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Use of Google Analytics
      </Typography>
      <Typography className={classes.note} variant="h7">
        We use Google Analytics to collect information about your use of the
        Site to generate reports and statistics on its performance. Google uses
        the collected data to track and monitor activity on the Site, and this
        data may be shared with other Google services. We do not use Google
        Analytics for personalized ads.
      </Typography>
      <Typography className={classes.note} variant="h7">
        If you don't want Google Analytics to be used in your browser, you can
        install the{' '}
        <a
          className={classes.link}
          href="https://tools.google.com/dlpage/gaoptout"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          Google Analytics Opt-Out Browser Add-On
        </a>
        , provided by Google.
      </Typography>
      <Typography className={classes.note} variant="h7">
        For more information on the privacy practices of Google, please visit
        the{' '}
        <a
          className={classes.link}
          href="https://policies.google.com/privacy"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          Google Privacy and Terms
        </a>{' '}
        web page.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Use of Hotjar
      </Typography>
      <Typography className={classes.note} variant="h7">
        We use Hotjar to gain insights into our users' needs and improve their
        experience on the Site. Hotjar collects data on user behaviour and
        devices, such as time spent on pages, links clicked, and interactions.
        This data, gathered through cookies and other technologies, helps us
        refine the Site based on user feedback.
      </Typography>
      <Typography className={classes.note} variant="h7">
        This includes a device's IP address (processed during your session and
        stored in a de-identified form), screen size, device type (unique
        identifiers), browser information, geographic location (country only),
        and preferred language. Hotjar stores this information in a
        pseudonymized user profile and is contractually prohibited from selling
        any data collected on our behalf.
      </Typography>
      <Typography className={classes.note} variant="h7">
        If you don't want Hotjar to be used in your browser, you can enable the
        "Do Not Track" (DNT) feature in your browser settings. When DNT is
        enabled, Hotjar will not track your activity on the Site. For more
        information on this, please refer to{' '}
        <a
          className={classes.link}
          href="https://www.hotjar.com/policies/do-not-track/"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          Hotjar's Do Not Track
        </a>{' '}
        policy.
      </Typography>
      <Typography className={classes.note} variant="h7">
        For more details, please refer to the{' '}
        <a
          className={classes.link}
          href="https://www.hotjar.com/legal/policies/privacy/"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          About Hotjar
        </a>{' '}
        section of Hotjar's support site.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Your Current Cookie Settings
      </Typography>
      <Typography className={classes.note} variant="h7">
        You have currently{' '}
        {getCookieConsentValue('CookieConsent') === 'true'
          ? 'accepted '
          : getCookieConsentValue('CookieConsent') === 'false'
            ? 'rejected '
            : 'not yet made a choice regarding '}
        permissions to analytics cookies such as Google Analytics and Hotjar. To
        change your consent status, please click{' '}
        <span className={classes.link} onClick={resetCookies}>
          here
        </span>
        .
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Information We Collect from Other Sources
      </Typography>
      <Typography className={classes.note} variant="h7">
        The Site leverages Google for authentication. If you authenticate with
        Google you will be asked to share your name, email address, language
        preference and profile picture with SkillUp. The Site only records a
        globally unique identifier that Google creates and associates with your
        account. If your Google account is disabled or deleted your information
        as stored by the Site becomes inaccessible.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Use of Information
      </Typography>
      <Typography className={classes.note} variant="h7">
        The information we collect is used for the exclusive purpose of
        optimizing the user experience.
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        Data Retention
      </Typography>
      <Typography className={classes.note} variant="h7">
        Our retention period for information required to support you between
        sessions is based on business needs and legal requirements. We retain
        the right to purge information associated with your login six months
        after your last access.
      </Typography>
      <Typography className={classes.subtitle} variant="h6">
        Contact Us
      </Typography>
      <Typography className={classes.note} variant="h7">
        If you have any questions about this Privacy Policy, please{' '}
        <a
          className={classes.link}
          href="mailto:privacy@cybera.ca?subject=Skill-Up%20Privacy%20Policy"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          contact us
        </a>
        .
      </Typography>
    </>
  );
};

export const PrivacyStatement = ({ modal }) => {
  const classes = useStyles();

  return modal ? (
    <PrivacyStatementText />
  ) : (
    <Container className={classes.page}>
      <Typography className={classes.title} variant="h4">
        Privacy Statement
      </Typography>
      <PrivacyStatementText />
    </Container>
  );
};

export default PrivacyStatement;
