import React, { useContext, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Typography from '@mui/material/Typography';

import { AuthContext } from 'Auth';
import { SignInPrompt } from 'modules/signInPrompt';
import { ButtonRoot, CustomTooltip } from './style';
import {
  useSaveUserPreferences,
  PREF_KEYS,
  GET_USER_PREFERENCES,
} from 'services/userPreferenceService';
import {
  favouritesRemovalStrategy,
  favouritesCheckStrategy,
  favouritesInsertStrategy,
} from './helper';

import { useQuery } from '@apollo/client';
import { PHOTON_GRAPH_URL } from 'environmentConfig';

export const Favourites = ({ detail, favType, overrideToggle = false }) => {
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [toggle, setToggle] = useState(false);

  const { currentUser } = useContext(AuthContext);

  const { saveUserPreferences } = useSaveUserPreferences();

  const { loading, data } = useQuery(GET_USER_PREFERENCES, {
    context: { uri: PHOTON_GRAPH_URL },
    variables: {
      userid: currentUser?.uid,
    },
    skip: !currentUser || overrideToggle,
  });

  const handleRemove = e => {
    e.stopPropagation();
    const tempFavs = favouritesRemovalStrategy[favType]?.(
      data?.userPreferences?.preferences[PREF_KEYS.FAVOURITES][favType],
      detail
    );
    updateFavourites(tempFavs);
  };

  const handleSave = () => {
    if (currentUser) {
      const tempFavs = [
        ...(data.userPreferences?.preferences[PREF_KEYS.FAVOURITES]?.[
          favType
        ] || []),
        detail,
      ];
      updateFavourites(tempFavs);
    } else {
      // Force the header to show, adds more context when the signin prompt is shown.
      window.dispatchEvent(new Event('revealHeader'));
      setShowLoginPrompt(true);
      setTempSignInFavourite({ favType: favType, detail: detail });
      setToggle(prev => !prev);
    }
  };

  const clearSignInFavourite = () => {
    sessionStorage.removeItem('tempFav');
  };

  const setTempSignInFavourite = temp => {
    sessionStorage.setItem('tempFav', JSON.stringify(temp));
  };

  const updateFavourites = async payload => {
    await saveUserPreferences({
      uid: currentUser.uid,
      prefs: {
        [PREF_KEYS.FAVOURITES]: {
          ...data.userPreferences?.preferences[PREF_KEYS.FAVOURITES],
          [favType]: payload,
        },
      },
    });
  };
  if (loading) return null;

  if (overrideToggle)
    return (
      <CustomTooltip
        title={
          <Typography sx={{ fontSize: '14px' }}>
            Remove from your favourites
          </Typography>
        }
      >
        <ButtonRoot>
          <IconButton
            onClick={e => overrideToggle(e, { favType, detail })}
            variant="plain"
            sx={{ color: '#E75B5B' }}
          >
            <FavoriteIcon />
          </IconButton>
        </ButtonRoot>
      </CustomTooltip>
    );

  return (
    <>
      {!currentUser && showLoginPrompt && (
        <SignInPrompt toggle={toggle} onClose={clearSignInFavourite} />
      )}
      {currentUser &&
      favouritesCheckStrategy[favType]?.(
        data.userPreferences?.preferences[PREF_KEYS.FAVOURITES]?.[favType],
        detail
      ) ? (
        <CustomTooltip
          title={
            <Typography sx={{ fontSize: '14px' }}>
              Remove from your favourites
            </Typography>
          }
        >
          <ButtonRoot>
            <IconButton
              onClick={handleRemove}
              variant="plain"
              sx={{ color: '#E75B5B' }}
            >
              <FavoriteIcon />
            </IconButton>
          </ButtonRoot>
        </CustomTooltip>
      ) : (
        <CustomTooltip
          title={
            <Typography sx={{ fontSize: '14px' }}>
              Save to your favourites
            </Typography>
          }
        >
          <ButtonRoot>
            <IconButton
              onClick={handleSave}
              variant="plain"
              sx={{ color: '#E75B5B' }}
            >
              <FavoriteBorder />
            </IconButton>
          </ButtonRoot>
        </CustomTooltip>
      )}
    </>
  );
};

// Specific function required for saving favourite during sign on.
export function favouritesSignInAction({ preferences }) {
  let prefs = preferences || {};
  const temp = JSON.parse(sessionStorage.getItem('tempFav'));
  if (temp) {
    const { favType, detail } = temp;
    const tempFavs = favouritesInsertStrategy[favType]?.(
      preferences?.[PREF_KEYS.FAVOURITES]?.[favType],
      detail
    );
    prefs = {
      [PREF_KEYS.FAVOURITES]: {
        ...preferences?.[PREF_KEYS.FAVOURITES],
        [favType]: tempFavs,
      },
    };
    if (preferences) {
      prefs = {
        ...preferences,
        ...prefs,
      };
    }
  }
  sessionStorage.removeItem('tempFav');
  return prefs;
}
