import React, { useState, useEffect, useContext, useRef } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Login } from '../../apps/Login/Login.jsx';
import { LoginBox, MobileDrawer, classes } from './style.js';
import { useSaveUserPreferences } from 'services/userPreferenceService';
import { AuthContext } from 'Auth';
import { useSearchParams } from 'react-router-dom';
export const LoginModalContext = React.createContext();

export const LoginModalProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const prevUser = useRef(null);

  const { saveUserProgressOnLogin } = useSaveUserPreferences();
  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState('');

  const [navigateAdminSettings, setNavigateAdminSettings] = useState(false);
  const [searchParams] = useSearchParams({});

  useEffect(() => {
    if (searchParams.get('login') === 'admin') {
      setFormType('login');
      setOpen(true);
      setNavigateAdminSettings(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentUser !== prevUser.current) {
      saveUserProgressOnLogin({
        uid: currentUser?.uid,
      });
    }
    prevUser.current = currentUser;
  }, [saveUserProgressOnLogin, currentUser]);

  const openLoginModal = formType => {
    setFormType(formType);
    setOpen(true);
  };

  const handleCloseModal = () => setOpen(false);

  const closeDrawer = () => {
    setOpen(false);
    setFormType('');
  };

  const isLargeScreen = useMediaQuery(
    `(min-width:${localStorage.getItem('MD_SCREEN')})`
  );

  const loginForm = type => {
    switch (type) {
      case 'login':
        return (
          <Login
            formType="login"
            handleClose={handleCloseModal}
            navigateAdminSettings={navigateAdminSettings}
          />
        );
      case 'register':
        return <Login formType="register" handleClose={handleCloseModal} />;
      default:
        return null;
    }
  };

  return (
    <LoginModalContext.Provider value={{ openLoginModal }}>
      {children}
      <div>
        {isLargeScreen ? (
          <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <LoginBox>
              <Card sx={{ height: 700, display: 'flex' }}>
                {isLargeScreen && (
                  <CardMedia
                    className={classes.image}
                    image="/assets/landingPage/login-modal.jpg"
                    title="Log In"
                  />
                )}
                <CardContent sx={{ display: 'flex', width: '50%' }}>
                  <IconButton
                    className={classes.close}
                    aria-label="close"
                    size="small"
                    onClick={() => setOpen(false)}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  {loginForm(formType)}
                </CardContent>
              </Card>
            </LoginBox>
          </Modal>
        ) : (
          <MobileDrawer anchor="bottom" open={open} onClose={closeDrawer}>
            <Card sx={{ height: '95vh', display: 'flex' }}>
              <CardContent className={classes.cardMobile}>
                <IconButton
                  className={classes.closeMobile}
                  aria-label="close"
                  size="small"
                  onClick={closeDrawer}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {loginForm(formType)}
              </CardContent>
            </Card>
          </MobileDrawer>
        )}
      </div>
    </LoginModalContext.Provider>
  );
};
